import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Alert, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TopBar from './components/TopBar';
import SalesPages from './pages/sales-pages';
import GET_ADMIN_USER from './queries/getAdminUser';
import Subscriptions from './pages/subscriptions';
import Payments from './pages/payments/Payments';

export const noAccessErrorMessage = 'sales-page.no-access';

interface IProps {
    userFullName?: string;
}

const Routing = (props: IProps) => {
    const { t } = useTranslation();
    const { data: adminUser, loading: adminUserLoading } = useQuery(
        GET_ADMIN_USER,
        {},
    );

    if (adminUserLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    marginTop: 20,
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const isAdminUserActive =
        adminUser?.admin_users &&
        adminUser?.admin_users[0] &&
        adminUser?.admin_users[0].active;
    const noAccess = !isAdminUserActive && !adminUserLoading;

    return (
        <BrowserRouter>
            <TopBar userFullName={props.userFullName} />
            {noAccess ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {t(noAccessErrorMessage)}
                </Alert>
            ) : (
                <Routes>
                    <Route path="/sales-pages" element={<SalesPages />} />
                    <Route path="/payments" element={<Payments />} />
                    <Route path="/subscriptions" element={<Subscriptions />} />
                    <Route path="/" element={<SalesPages />} />
                </Routes>
            )}
        </BrowserRouter>
    );
};

export default Routing;
