import { gql } from '@apollo/client';

const SET_CAMPWIRE_USER_FLAG = gql`
    mutation ChangeCampwireUserFlag($userId: Int!, $campwireUser: Boolean!)  {
        update_users_by_pk(
            pk_columns: {id: $userId},
            _set: {
                campwireUser: $campwireUser
            }
        ) {
            id
            state
        }
    }
`;

export default SET_CAMPWIRE_USER_FLAG;
