import { Column } from '@tanstack/react-table';
import { Button } from './button';
import { ChevronDown, ChevronUp } from 'lucide-react';

function TableSortedHeader<T>({
    title,
    column,
}: {
    title: string;
    column: Column<T>;
}) {
    return (
        <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
            {title}
            {column.getIsSorted() === 'asc' && (
                <ChevronUp className="ml-2 size-4" />
            )}
            {column.getIsSorted() === 'desc' && (
                <ChevronDown className="ml-2 size-4" />
            )}
        </Button>
    );
}

export default TableSortedHeader;
