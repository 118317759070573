import { gql } from '@apollo/client';

const SAVE_ROW_CHANGES = gql`
    mutation ChangeUserState(
            $publicPath: String, 
            $serviceName: String,
            $serviceDescription: String,
            $serviceVat: Float
            )  {
        update_sales_pages(
            where: {publicPath: {_eq: $publicPath}},
            _set: {
                serviceName: $serviceName
                serviceDescription: $serviceDescription
                serviceVat: $serviceVat
            }
        ) {
            affected_rows
        }
    }
`;

export default SAVE_ROW_CHANGES;
