import { env } from './utils/env';

interface IConfig {
    ENV: string;
    EEZYPAY_HASURA_HOST: string;
    EEZYPAY_WEB_HOST: string;
    AAVA_HOST: string;
}

export const config: IConfig = {
    ENV: env('REACT_APP_ENV', 'dev'),
    EEZYPAY_HASURA_HOST: env('REACT_APP_EEZYPAY_HASURA_HOST', 'http://localhost:8090'),
    EEZYPAY_WEB_HOST: env('REACT_APP_EEZYPAY_WEB_HOST', 'http://localhost:3001'),
    AAVA_HOST: env('REACT_APP_AAVA_HOST', 'https://eezy-1.test.aavaohjelmistot.fi'),
};

export const msalConfig = {
    auth: {
        clientId: env('REACT_APP_MSAL_CLIENT_ID', '01f76ff2-3410-4dbf-91ad-4008608d4ed7'),
        authority: env(
            'REACT_APP_MSAL_AUTHORITY',
            'https://login.microsoftonline.com/8b8a93d4-f49e-455d-a2d3-385c82d7f15e',
        ), // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: env('REACT_APP_MSAL_REDIRECT_URL', 'http://localhost:3002'),
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};
