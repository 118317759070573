import { gql } from '@apollo/client';

const CHANGE_SUBSCRIPTION_END_DATE = gql`
    mutation ChangeSubscriptionEndDate(
        $subscriptionId: Int!
        $endDate: timestamptz!
    ) {
        update_subscriptions_by_pk(
            pk_columns: { id: $subscriptionId }
            _set: {
                endDate: $endDate
                reminderSent168: null
                reminderSent72: null
                reminderSent6: null
            }
        ) {
            id
            endDate
        }
    }
`;

export default CHANGE_SUBSCRIPTION_END_DATE;
