import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { add, isAfter, isBefore } from 'date-fns';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GET_SUBSCRIPTIONS from './queries/getSubscriptions';
import SubscriptionsTable from '../../components/SubscriptionsTable';
import { Input } from 'components/ui/input';
import { fuzzySearch } from 'utils/fuzzySearch';
import { SearchIcon } from 'lucide-react';

const Subscriptions: React.FC = () => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [searchTerm, setSearchTerm] = useState('');

    const { data: subscriptionsData } = useQuery<SubscriptionQueryResult>(
        GET_SUBSCRIPTIONS,
        {
            variables: {
                searchTerm: '%%', // `%${props.searchQuery}%`
            },
            onError: () => setErrorMessage(t('error.fetch-subscriptions')),
        },
    );

    const subscriptions = fuzzySearch(
        subscriptionsData?.subscriptions.map((s) => ({
            ...s,
            fullName: `${s.subscriptionsUsers.firstName} ${s.subscriptionsUsers.lastName}`,
        })) ?? [],
        searchTerm,
        ['subscriptionsUsers.email', 'fullName'],
    );

    const statistics = [
        {
            title: t('subscriptions.total-subscriptions'),
            value: subscriptionsData?.subscriptions.length,
        },
        {
            title: t('subscriptions.active-subscriptions'),
            value: subscriptionsData?.subscriptions.filter(
                (s) =>
                    isBefore(new Date(), new Date(s.campwireEndDate)) ||
                    isBefore(new Date(), new Date(s.endDate)),
            ).length,
        },
        {
            title: t('subscriptions.subscriptions-ending-in-week'),
            value: subscriptionsData?.subscriptions.filter(
                (s) =>
                    (isBefore(new Date(), new Date(s.campwireEndDate)) &&
                        isAfter(
                            add(new Date(), { weeks: 1 }),
                            new Date(s.campwireEndDate),
                        )) ||
                    (isBefore(new Date(), new Date(s.endDate)) &&
                        isAfter(
                            add(new Date(), { weeks: 1 }),
                            new Date(s.endDate),
                        )),
            ).length,
        },
    ];
    return (
        <div className="container">
            {
                <Collapse in={!!errorMessage}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMessage(undefined);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {errorMessage}
                    </Alert>
                </Collapse>
            }
            <Typography
                variant="h5"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 700,
                    margin: 3,
                }}
            >
                {t('menu.subscriptions')}
            </Typography>

            <div className="flex justify-center gap-16">
                {statistics.map((stat) => (
                    <div
                        className="text-center bg-blue-100 p-4 rounded-sm"
                        key={stat.title}
                    >
                        <p>{stat.title}</p>
                        <p className="text-2xl font-bold mt-4">{stat.value}</p>
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-2 my-6">
                <div className="relative">
                    <SearchIcon className="absolute top-1/2 left-2 -translate-y-1/2 size-4" />
                    <Input
                        id="userSearch"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder={t('subscriptions.search-users')}
                        className="max-w-80 pl-8"
                    />
                </div>
            </div>
            <SubscriptionsTable subscriptions={subscriptions} />
        </div>
    );
};

export default Subscriptions;
