import { gql } from '@apollo/client';

const CHANGE_ALL_USER_SALES_PAGES_STATE = gql`
    mutation ChangeAllUserSalesPagesState($userId: Int, $state: Int)  {
        update_sales_pages(
            where: {userId: {_eq: $userId}},
            _set: {
                state: $state
            }
        ) {
            affected_rows
        }
    }
`;

export default CHANGE_ALL_USER_SALES_PAGES_STATE;
