import Fuse from 'fuse.js';

export function fuzzySearch<T>(
    objects: T[],
    searchTerm: string,
    searchKeys: Record<string, number> | string[],
    options: { threshold?: number } = {},
): T[] {
    const s = searchTerm.toLowerCase().trim();

    // Default behaviour for empty string -- all match
    if (!s) return objects;

    const keys = Array.isArray(searchKeys)
        ? searchKeys
        : Object.entries(searchKeys).map(([key, weight]) => ({
              name: key,
              weight,
          }));

    const fuse = new Fuse([objects].flat() as T[], {
        keys,
        includeScore: true,
        shouldSort: true,
        threshold: options.threshold ?? 0.3,
        minMatchCharLength: 1,
    });

    const results = fuse.search(s).map(({ item }) => item);

    return results;
}
