import { gql } from '@apollo/client';

const CHANGE_USER_STATE = gql`
    mutation ChangeUserState($userId: Int!, $state: Int!)  {
        update_users_by_pk(
            pk_columns: {id: $userId},
            _set: {
                state: $state
            }
        ) {
            id
            state
        }
    }
`;

export default CHANGE_USER_STATE;
