import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoginTypes } from '../App';

export const SignOutButton = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const handleLogout = (logoutType: LoginTypes.Redirect | LoginTypes.Popup) => {
        if (logoutType === LoginTypes.Redirect) {
            instance.logoutRedirect({
                postLogoutRedirectUri: '/',
            });
        }
        if (logoutType === LoginTypes.Popup) {
            instance.logoutPopup({
                postLogoutRedirectUri: '/',
                mainWindowRedirectUri: '/', // redirects the top level app after logout
            });
        }
    };

    return (
        <Button variant="contained" color="info" onClick={() => handleLogout(LoginTypes.Redirect)}>{t('menu.sign-out')}</Button>
    );
};
