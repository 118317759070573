import { gql } from '@apollo/client';

const GET_CAMPWIRE_PAYMENTS = gql`
    query getCampwirePayments($searchTerm: String) {
        transactions(
            order_by: { completed: desc }
            where: {
                _and: {
                    status: { _eq: "COMPLETE" }
                    thirdParty: { _eq: "campwire" }
                }
            }
        ) {
            serviceName
            orderNumber
            paymentBrand
            completed
            servicePrice
            thirdParty
            status
            aavaId
            serviceVat
        }
    }
`;

export default GET_CAMPWIRE_PAYMENTS;
