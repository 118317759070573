import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../config';
import { SalesPageStates } from '../constants/sales-pages';

export interface IRowData {
    aavaId: number;
    creationDate: string;
    publicPath: string;
    occupationId: number;
    salesPagesUsers: {
        id: number;
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        state: number;
        language: string;
    };
    serviceDescription: string;
    serviceName: string;
    servicePrice: number;
    serviceVat: number;
    state: number;
}

interface IRowProps {
    rowFromApi: IRowData;
    setUserState: (isActive: boolean, userId: number) => void;
    setSalesPageState: (state: number, publicPath: string) => void;
    saveRowFields: (row: IRowData) => void;
}

function Row(props: IRowProps) {
    const { t } = useTranslation();
    const { rowFromApi, setUserState, setSalesPageState, saveRowFields } =
        props;
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = useState<IRowData>(rowFromApi);
    useEffect(() => {
        if (rowFromApi) {
            setRow(rowFromApi);
        }
    }, [rowFromApi]);

    const servicePrice =
        row && `${(row.servicePrice / 100).toFixed(2).replace('.', ',')}€`;

    const handleSlesPageStateChange = (event: SelectChangeEvent<number>) => {
        setRow({
            ...row,
            ...{ state: event.target.value as number },
        });
        setSalesPageState(event.target.value as number, row.publicPath);
    };

    const getSalesPageState = (rowData: IRowData) => {
        switch (rowData.state) {
            case SalesPageStates.Closed:
                return t('sales-page.closed').toUpperCase();
            case SalesPageStates.Active:
                return t('sales-page.active').toUpperCase();
            case SalesPageStates.WaitingForApproval:
                return t('sales-page.waiting-for-approval').toUpperCase();
            default:
                return '-';
        }
    };

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.salesPagesUsers?.email}
                </TableCell>
                <TableCell>{row.publicPath}</TableCell>
                <TableCell>{row.serviceName}</TableCell>
                <TableCell align="right">{servicePrice}</TableCell>
                <TableCell align="right">{getSalesPageState(row)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Container sx={{ display: 'flex', margin: 1 }}>
                            <Box sx={{ marginRight: 3, flexGrow: 1 }}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {t('user.user')}:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('user.full-name')}: </b>
                                    {row?.salesPagesUsers?.firstName}{' '}
                                    {row?.salesPagesUsers?.lastName}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('user.email')}:</b>{' '}
                                    {row?.salesPagesUsers?.email}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('user.phone')}:</b>{' '}
                                    {row?.salesPagesUsers?.phone}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('user.language')}: </b>
                                    {t(
                                        `language.${row?.salesPagesUsers?.language}`,
                                    )}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('sales-page.active')}</b>
                                    <Switch
                                        checked={!!row?.salesPagesUsers?.state}
                                        onChange={(val) => {
                                            setRow({
                                                ...row,
                                                salesPagesUsers: {
                                                    ...row?.salesPagesUsers,
                                                    ...{
                                                        state: val.target
                                                            .checked
                                                            ? 1
                                                            : 0,
                                                    },
                                                },
                                            });
                                            setUserState(
                                                val.target.checked,
                                                row?.salesPagesUsers?.id,
                                            );
                                        }}
                                    />
                                </Typography>
                            </Box>
                            <Box sx={{ margin: 1, flexGrow: 6 }}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {t('sales-page.sales-page')}:
                                </Typography>
                                <TextField
                                    multiline
                                    value={row?.serviceName}
                                    onChange={(e) => {
                                        setRow({
                                            ...row,
                                            serviceName: e.target.value,
                                        });
                                    }}
                                    id="service-name"
                                    label={t('sales-page.service-name')}
                                    sx={{ width: '100%', marginBottom: 2 }}
                                />
                                <TextField
                                    multiline
                                    value={row?.serviceDescription || ''}
                                    onChange={(e) => {
                                        setRow({
                                            ...row,
                                            serviceDescription: e.target.value,
                                        });
                                    }}
                                    id="service-description"
                                    label={t('sales-page.service-description')}
                                    sx={{ width: '100%', marginBottom: 2 }}
                                />
                                <TextField
                                    multiline
                                    value={row?.serviceVat}
                                    onChange={(e) => {
                                        setRow({
                                            ...row,
                                            serviceVat:
                                                Number(e.target.value) ||
                                                (Number(e.target.value) === 0
                                                    ? 0
                                                    : row.serviceVat),
                                        });
                                    }}
                                    id="service-vat"
                                    label={t('sales-page.service-vat')}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => {
                                        saveRowFields(row);
                                    }}
                                    sx={{ margin: '8px 30px 30px 20px' }}
                                >
                                    {t('button.save-changes')}
                                </Button>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('sales-page.service-price')}: </b>
                                    {servicePrice}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('sales-page.creation-date')}:</b>{' '}
                                    {row?.creationDate}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('sales-page.occupation')}:</b>{' '}
                                    {t(`occupations:${row?.occupationId}`)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                >
                                    <b>{t('sales-page.sales-page-link')}: </b>
                                    <Link
                                        href={`${config.EEZYPAY_WEB_HOST}/pay/${row?.publicPath}`}
                                        target="_blank"
                                    >
                                        {`${config.EEZYPAY_WEB_HOST}/pay/${row?.publicPath}`}
                                    </Link>
                                </Typography>
                                {row?.aavaId && (
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                        component="div"
                                    >
                                        <b>Aava ID: </b>
                                        <Link
                                            href={`${config.AAVA_HOST}/aava/#/sales_pages/${row?.aavaId}`}
                                            target="_blank"
                                        >
                                            {row?.aavaId}
                                        </Link>
                                    </Typography>
                                )}
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                    sx={{ marginTop: 2, width: 300 }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            {t('sales-page.state')}
                                        </InputLabel>
                                        <Select
                                            labelId="sales-page-state-select-label"
                                            id="sales-page-state-select"
                                            value={row?.state}
                                            label={t('sales-page.state')}
                                            onChange={handleSlesPageStateChange}
                                        >
                                            <MenuItem
                                                value={SalesPageStates.Closed}
                                            >
                                                {t('sales-page.closed')}
                                            </MenuItem>
                                            <MenuItem
                                                value={SalesPageStates.Active}
                                            >
                                                {t('sales-page.active')}
                                            </MenuItem>
                                            <MenuItem
                                                value={
                                                    SalesPageStates.WaitingForApproval
                                                }
                                            >
                                                {t(
                                                    'sales-page.waiting-for-approval',
                                                )}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Typography>
                            </Box>
                        </Container>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

interface ISalesPagesTableProps {
    salesPages: IRowData[];
    setUserState: (isActive: boolean, userId: number) => void;
    setSalesPageState: (state: number, publicPath: string) => void;
    saveRowFields: (row: IRowData) => void;
    loading: boolean;
}

export default function SalesPagesTable(props: ISalesPagesTableProps) {
    const { t } = useTranslation();
    const {
        salesPages,
        setUserState,
        setSalesPageState,
        saveRowFields,
        loading,
    } = props;
    return (
        <TableContainer
            component={Paper}
            sx={{ maxHeight: 'calc(100vh - 150px)' }}
        >
            <Table stickyHeader size="small" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {loading && <CircularProgress size={24} />}
                        </TableCell>
                        <TableCell>
                            <b>{t('user.user')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('sales-page.public-path')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('sales-page.service-name')}</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>{t('sales-page.service-price')}</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>{t('sales-page.state')}</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {salesPages?.map((row: IRowData) => (
                        <Row
                            key={row.publicPath}
                            rowFromApi={row}
                            setUserState={setUserState}
                            setSalesPageState={setSalesPageState}
                            saveRowFields={saveRowFields}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
