import { gql } from '@apollo/client';

const GET_ADMIN_USER = gql`
    query checkAdmin {
        admin_users {
            active
        }
    }
`;

export default GET_ADMIN_USER;
