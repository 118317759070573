import { useState } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GET_CAMPWIRE_PAYMENTS from './queries/getCampwirePayments';
import PaymentsTable from '../../components/PaymentsTable';

const Payments: React.FC = () => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string>();

    const { data: paymentsData } = useQuery(GET_CAMPWIRE_PAYMENTS, {
        onError: () => setErrorMessage(t('error.fetch-payments')),
    });
    const payments = paymentsData?.transactions;

    return (
        <>
            {
                <Collapse in={!!errorMessage}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMessage(undefined);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {errorMessage}
                    </Alert>
                </Collapse>
            }
            <Typography
                variant="h5"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 700,
                    margin: 3,
                }}
            >
                {t('menu.payments')}
            </Typography>
            <PaymentsTable payments={payments} />
        </>
    );
};

export default Payments;
