import { gql } from '@apollo/client';

const GET_SUBSCRIPTIONS = gql`
    query getSubscriptions($searchTerm: String) {
        subscriptions(order_by: { id: desc }) {
            id
            startDate
            endDate
            campwireStartDate
            campwireEndDate
            subscriptionsUsers {
                id
                firstName
                lastName
                email
                phone
                state
                language
                campwireUser
            }
        }
    }
`;

export default GET_SUBSCRIPTIONS;
