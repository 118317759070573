import * as React from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';

import { cn } from 'utils';
import { Button } from 'components/ui/button';
import { Calendar } from 'components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { formatDate } from 'utils/date';

export function DatePicker({
    value,
    onChange,
    minDate,
}: {
    value: string;
    onChange: (date: Date | undefined) => void;
    minDate?: Date;
}) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn(
                        'w-full max-w-[280px] justify-start text-left font-normal',
                        !value && 'text-muted-foreground',
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {value ? formatDate(value) : <span>-</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={new Date(value)}
                    onSelect={onChange}
                    fromDate={minDate}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}
