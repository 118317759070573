import { gql } from '@apollo/client';

const CHANGE_SALES_PAGE_STATE = gql`
    mutation ChangeSalesPageState($publicPath: String, $state: Int)  {
        update_sales_pages(
            where: {publicPath: {_eq: $publicPath}},
            _set: {
                state: $state
            }
        ) {
            affected_rows
        }
    }
`;

export default CHANGE_SALES_PAGE_STATE;
