import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { changeLanguage, trans, UiLanguage } from '../utils/i18n';

interface ILanguageChangeProps {
    language?: UiLanguage;
}

const LanguageOptions = (props: ILanguageChangeProps) => {
    const { language } = props;
    const [localLanguage, setLocalLanguage] = useState(language);
    return (
        <Box>
            <Button
                aria-label={trans('menu.lang-change-fi')}
                onClick={() => {
                    setLocalLanguage('fi');
                    changeLanguage('fi');
                }}
                sx={{
                    color: 'white',
                    textDecoration: localLanguage === 'fi' ? 'underline' : 'none',
                }}
            >
                FI
            </Button>
            <Button
                aria-label={trans('menu.lang-change-en')}
                onClick={() => {
                    setLocalLanguage('en');
                    changeLanguage('en');
                }}
                sx={{
                    color: 'white',
                    borderLeft: '1px solid white',
                    textDecoration: localLanguage === 'en' ? 'underline' : 'none',
                    borderRadius: 0,
                }}
            >
                EN
            </Button>
        </Box>
    );
};

export default LanguageOptions;
