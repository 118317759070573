import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Collapse, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import SalesPagesTable, { IRowData } from '../../components/SalesPagesTable';
import GET_SALES_PAGES from './queries/getSalesPages';
import CHANGE_SALES_PAGE_STATE from './queries/changeSalesPageState';
import CHANGE_USER_STATE from './queries/changeUserState';
import CHANGE_ALL_USER_SALES_PAGES_STATE from './queries/changeAllUserSalesPagesState';
import SAVE_ROW_CHANGES from './queries/saveRowChanges';
import { getErrorCode } from '../../utils/apollo';
import { noAccessErrorMessage } from '../../Routing';

const SalesPages: React.FC = () => {
    const { t } = useTranslation();

    const [actionFeedback, setActionFeedback] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const { data: salesPagesData, loading: salesPagesLoading } = useQuery(GET_SALES_PAGES, {
        variables: {
            searchTerm: '%%', // `%${props.searchQuery}%`
        },
        onError: (error) => {
            if (getErrorCode(error) === 'jwt-missing-role-claims') {
                setErrorMessage(noAccessErrorMessage);
            } else {
                setErrorMessage(t('error.fetch-sales-pages'));
            }
        },
    });
    const salesPages = salesPagesData?.sales_pages;

    const [userStateMutationVariables, setUserStateMutationVariables] = useState<{
        state: number,
        userId: number
    }>();
    const [setUserState, { loading: userStateInProgress }] = useMutation(
        CHANGE_USER_STATE,
        {
            variables: userStateMutationVariables,
            onError: () => setErrorMessage(t('error.change-user-state')),
        },
    );
    const [setAllUserSalesPagesState, { loading: userAllPagesStateInProgress }] = useMutation(
        CHANGE_ALL_USER_SALES_PAGES_STATE,
        {
            variables: userStateMutationVariables,
            onError: () => setErrorMessage(t('error.change-all-sales-pages-state')),
            refetchQueries: () => [
                {
                    query: GET_SALES_PAGES,
                    variables: {
                        searchTerm: '%%',
                    },
                },
            ],
        },
    );
    const changeUserState = async (isActive: boolean, userId: number) => {
        const state = isActive ? 1 : 0;
        await setUserStateMutationVariables({ state, userId });
        await setUserState();
        setAllUserSalesPagesState();
    };

    const [rowMutationVariables, setRowMutationVariables] = useState<{
        publicPath: string,
        serviceName: string,
        serviceDescription?: string,
        serviceVat: number
    }>();
    const [saveRowFieldsToDb, { loading: saveRowFieldsToDbInProgress }] = useMutation(
        SAVE_ROW_CHANGES,
        {
            variables: rowMutationVariables,
            onCompleted: (result) => {
                const affectedRows = result?.update_sales_pages?.affected_rows;
                if (affectedRows === 1) {
                    setActionFeedback(t('ui-feedback.save-success'));
                }
            },
            onError: () => setErrorMessage(t('error.save-row-changes')),
            refetchQueries: () => [
                {
                    query: GET_SALES_PAGES,
                    variables: {
                        searchTerm: '%%',
                    },
                },
            ],
        },
    );
    const saveRowFields = async (row: IRowData) => {
        await setRowMutationVariables({
            publicPath: row.publicPath,
            serviceName: row.serviceName,
            serviceDescription: row.serviceDescription,
            serviceVat: row.serviceVat,
        });
        saveRowFieldsToDb();
    };

    const [salesPageStateMutationVariables, setSalesPageStateMutationVariables] = useState<{
        state: number,
        publicPath: string
    }>();
    const [setSalesPageState, { loading: salesPageStateInProgress }] = useMutation(
        CHANGE_SALES_PAGE_STATE,
        {
            variables: salesPageStateMutationVariables,
            onError: () => setErrorMessage(t('error.change-sales-page-state')),
            refetchQueries: () => [
                {
                    query: GET_SALES_PAGES,
                    variables: {
                        searchTerm: '%%',
                    },
                },
            ],
        },
    );
    const changeSalesPageState = async (state: number, publicPath: string) => {
        await setSalesPageStateMutationVariables({ state, publicPath });
        setSalesPageState();
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setActionFeedback(undefined)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    if (errorMessage === noAccessErrorMessage) {
        return (
            <Alert
                severity="error"
                sx={{ mb: 2 }}
            >
                {t(noAccessErrorMessage)}
            </Alert>
        );
    }

    return (
        <>
            {
                <Collapse in={!!errorMessage}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMessage(undefined);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {errorMessage}
                    </Alert>
                </Collapse>
            }
            <Typography
                variant="h5"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 700,
                    margin: 3,
                }}
            >
                {t('menu.sales-pages')}
            </Typography>
            <Snackbar
                open={!!actionFeedback}
                autoHideDuration={6000}
                onClose={() => setActionFeedback(undefined)}
                message={actionFeedback}
                action={action}
            />
            <SalesPagesTable
                salesPages={salesPages}
                setSalesPageState={changeSalesPageState}
                setUserState={changeUserState}
                saveRowFields={saveRowFields}
                loading={
                    salesPagesLoading ||
                    salesPageStateInProgress ||
                    userStateInProgress ||
                    userAllPagesStateInProgress ||
                    saveRowFieldsToDbInProgress
                }
            />
        </>
    );
};

export default SalesPages;
