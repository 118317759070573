import { gql } from '@apollo/client';

const GET_SALES_PAGES = gql`
    query getSalesPages($searchTerm: String) {
        sales_pages(
            order_by: { id: desc }
            where: {
                _and: {
                    deleted: { _is_null: true }
                    _or: [
                        { serviceName: { _ilike: $searchTerm } }
                        { serviceDescription: { _ilike: $searchTerm } }
                        { publicPath: { _ilike: $searchTerm } }
                    ]
                    thirdParty: { _is_null: true }
                }
            }
        ) {
            id
            aavaId
            creationDate
            publicPath
            occupationId
            serviceDescription
            serviceName
            servicePrice
            serviceVat
            state
            salesPagesUsers {
                id
                firstName
                lastName
                email
                phone
                state
                language
            }
        }
    }
`;

export default GET_SALES_PAGES;
