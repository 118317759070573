import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils/date';

export interface IRowData {
    id: number;
    serviceName: string;
    servicePrice: number;
    serviceVat: number;
    aavaId: number;
    orderNumber: number;
    paymentBrand: string;
    status: string;
    completed: string;
}

interface IRowProps {
    rowFromApi: IRowData;
}

function Row(props: IRowProps) {
    const { rowFromApi } = props;
    const [row, setRow] = useState<IRowData>(rowFromApi);
    useEffect(() => {
        if (rowFromApi) {
            setRow(rowFromApi);
        }
    }, [rowFromApi]);

    const servicePrice =
        row && `${(row.servicePrice / 100).toFixed(2).replace('.', ',')}€`;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                    {row?.serviceName}
                </TableCell>
                <TableCell>{servicePrice}</TableCell>
                <TableCell>{row.serviceVat}</TableCell>
                <TableCell>{row.aavaId}</TableCell>
                <TableCell>{row.orderNumber}</TableCell>
                <TableCell>{row.paymentBrand}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{formatDate(row?.completed)}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

interface ISalesPagesTableProps {
    payments: IRowData[];
}

export default function SalesPagesTable(props: ISalesPagesTableProps) {
    const { t } = useTranslation();
    const { payments } = props;

    return (
        <TableContainer
            component={Paper}
            sx={{ maxHeight: 'calc(100vh - 150px)' }}
        >
            <Table stickyHeader size="medium" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>{t('payments.service-name')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('payments.service-price')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('payments.service-vat')}</b>
                        </TableCell>
                        <TableCell>
                            <b>Aava ID</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('payments.order-number')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('payments.payment-brand')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('payments.status')}</b>
                        </TableCell>
                        <TableCell>
                            <b>{t('payments.completed')}</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments?.map((row: IRowData) => (
                        <Row key={row.id} rowFromApi={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
