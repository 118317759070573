import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils/date';
import { ColumnDef, Row, flexRender } from '@tanstack/react-table';
import { DataTable } from './ui/data-table';
import TableSortedHeader from './ui/table-sorted-header';
import SubscriptionsModal from './SubscriptionsModal';
import { TableCell, TableRow } from './ui/table';
import { compareAsc } from 'date-fns/esm';

const SubscriptionsTableRow = ({ row }: { row: Row<Subscription> }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <SubscriptionsModal
                subscription={row.original}
                open={open}
                setOpen={setOpen}
            />
            <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                onClick={() => setOpen(true)}
                className="cursor-pointer"
            >
                {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </>
    );
};

interface ISubscriptionsTableProps {
    subscriptions: Subscription[];
}

export default function SubscriptionsTable({
    subscriptions,
}: ISubscriptionsTableProps) {
    const { t } = useTranslation();

    const tableColumns: ColumnDef<Subscription>[] = [
        {
            id: 'email',
            accessorKey: 'subscriptionsUsers.email',
            header: ({ column }) => (
                <TableSortedHeader title={t('user.email')} column={column} />
            ),
        },
        {
            header: t('user.full-name'),
            accessorFn: (row) =>
                `${row.subscriptionsUsers.firstName} ${row.subscriptionsUsers.lastName}`,
        },
        {
            header: t('subscriptions.start-date'),
            accessorFn: (row) => formatDate(row.startDate),
        },
        {
            id: 'end-date',
            header: ({ column }) => (
                <TableSortedHeader
                    title={t('subscriptions.end-date')}
                    column={column}
                />
            ),
            accessorFn: (row) => formatDate(row.endDate),
            sortingFn: (rowA, rowB) =>
                compareAsc(
                    new Date(rowA.original.endDate),
                    new Date(rowB.original.endDate),
                ),
        },
        {
            id: 'campwire-end-date',
            header: ({ column }) => (
                <TableSortedHeader
                    title={t('subscriptions.campwire-end-date')}
                    column={column}
                />
            ),
            accessorFn: (row) => formatDate(row.campwireEndDate),
            sortingFn: (rowA, rowB) =>
                compareAsc(
                    new Date(rowA.original.campwireEndDate),
                    new Date(rowB.original.campwireEndDate),
                ),
        },
    ];

    return (
        <>
            <DataTable
                columns={tableColumns}
                data={subscriptions}
                TableRowComponent={SubscriptionsTableRow}
            />
        </>
    );
}
