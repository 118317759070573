import moment from 'moment';

export const formatDate = (date: string) => {
    const momentDate = moment(date);

    if (!momentDate.isValid()) {
        return '-';
    }

    return momentDate.format('DD.MM.YYYY');
};
