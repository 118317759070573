import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { loginRequest, config } from './config';
import { hasuraPath } from './constants';
import Routing from './Routing';

export enum LoginTypes {
    Popup = 'popup',
    Redirect = 'redirect',
}
interface IDecodedToken {
    exp: number;
}

function App() {
    const { accounts, instance, inProgress } = useMsal();
    const [error, setError] = useState<any>();

    const handleLogin = (loginType: LoginTypes.Redirect | LoginTypes.Popup) => {
        if (loginType === LoginTypes.Popup) {
            instance.loginPopup(loginRequest).catch((e) => {
                setError(e);
            });
        }
        if (loginType === LoginTypes.Redirect) {
            instance.loginRedirect(loginRequest).catch((e) => {
                setError(e);
            });
        }
    };
    const isAuthenticated = useIsAuthenticated();
    const userFullName = accounts[0] && accounts[0].name;

    const createApolloClient = () =>
        new ApolloClient({
            uri: config.EEZYPAY_HASURA_HOST + hasuraPath,
            cache: new InMemoryCache(),
        });

    const asyncTokenLookup = async () => {
        const account = accounts[0];
        const expTimestamp = account?.idTokenClaims?.exp || 0;
        const isTokenExpired = expTimestamp * 1000 - new Date().valueOf() < 1;
        if (inProgress === 'none' || inProgress === 'startup') {
            if (account) {
                try {
                    const result = await instance.acquireTokenSilent({
                        ...loginRequest,
                        forceRefresh: isTokenExpired,
                        account,
                    });
                    const { idToken } = result;
                    const decodedToken: IDecodedToken = idToken
                        ? jwtDecode(idToken)
                        : ({} as IDecodedToken);
                    const isNewTokenExpired =
                        decodedToken.exp * 1000 - new Date().valueOf() < 1;
                    if (isNewTokenExpired) {
                        return instance.acquireTokenRedirect(loginRequest);
                    }
                    return idToken;
                } catch (err) {
                    if (err instanceof InteractionRequiredAuthError) {
                        return instance.acquireTokenRedirect(loginRequest);
                    }
                }
            }
            return instance.acquireTokenRedirect(loginRequest);
        }
        return undefined;
    };

    const withToken = setContext(async (_, { headers }) => {
        const token = await asyncTokenLookup();
        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : null,
            },
        };
    });

    const [apolloClient, setApolloClient] = useState<ApolloClient<any>>(
        createApolloClient(),
    );
    useEffect(() => {
        const httpLink = createHttpLink({
            uri: config.EEZYPAY_HASURA_HOST + hasuraPath,
        });

        const client = new ApolloClient({
            link: from([withToken, httpLink]),
            cache: new InMemoryCache(),
        });
        setApolloClient(client);
    }, []);

    if (inProgress !== 'none') {
        return (
            <Container
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ display: 'flex', marginTop: 20 }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (!isAuthenticated) {
        handleLogin(LoginTypes.Redirect);
    }

    if (error && inProgress === 'none') {
        return <div>ERROR: {JSON.stringify(error, null, 2)}</div>;
    }

    return (
        <ApolloProvider client={apolloClient}>
            <Routing userFullName={userFullName} />
        </ApolloProvider>
    );
}

export default App;
