import { Switch } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import { Button } from './ui/button';
import { useMutation } from '@apollo/client';
import CHANGE_USER_STATE from 'pages/sales-pages/queries/changeUserState';
import { useToast } from './ui/use-toast';
import CHANGE_ALL_USER_SALES_PAGES_STATE from 'pages/sales-pages/queries/changeAllUserSalesPagesState';
import GET_SALES_PAGES from 'pages/sales-pages/queries/getSalesPages';
import SET_CAMPWIRE_USER_FLAG from 'pages/subscriptions/queries/setCampwrireUserFlag';
import GET_SUBSCRIPTIONS from 'pages/subscriptions/queries/getSubscriptions';
import CHANGE_SUBSCRIPTION_END_DATE from 'pages/subscriptions/queries/changeSubscriptionEndDate';
import CHANGE_CAMPWIRE_SUBSCRIPTION_END_DATE from 'pages/subscriptions/queries/changeCampwireSubscriptionEndDate';
import Dialog from './ui/dialog';
import { DatePicker } from './ui/date-picker';

const monthIntervals = [1, 3, 6, 12];

function SubscriptionsModal({
    subscription,
    open,
    setOpen,
}: {
    subscription: Subscription;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation();
    const { toast } = useToast();

    const [userStateMutationVariables, setUserStateMutationVariables] =
        useState<{
            state: number;
            userId: number;
        }>();

    const [
        campwireUserFlagMutationVariables,
        setCampwireUserFlagMutationVariables,
    ] = useState<{
        campwireUser: boolean;
        userId: number;
    }>();

    const [setUserState] = useMutation(CHANGE_USER_STATE, {
        variables: userStateMutationVariables,
        onError: () =>
            toast({
                title: t('error.change-user-state'),
                variant: 'destructive',
            }),
    });

    const [setAllUserSalesPagesState] = useMutation(
        CHANGE_ALL_USER_SALES_PAGES_STATE,
        {
            variables: userStateMutationVariables,
            onError: () =>
                toast({
                    title: t('error.change-all-sales-pages-state'),
                    variant: 'destructive',
                }),
            refetchQueries: () => [
                {
                    query: GET_SALES_PAGES,
                    variables: {
                        searchTerm: '%%',
                    },
                },
            ],
        },
    );

    const [setCampwireUserFlag] = useMutation(SET_CAMPWIRE_USER_FLAG, {
        variables: campwireUserFlagMutationVariables,
        onError: () =>
            toast({
                title: t('error.change-user-campwire-state'),
                variant: 'destructive',
            }),
        refetchQueries: () => [
            {
                query: GET_SUBSCRIPTIONS,
                variables: {
                    searchTerm: '%%',
                },
            },
        ],
    });

    const [setEndDate] = useMutation(CHANGE_SUBSCRIPTION_END_DATE, {
        onError: () =>
            toast({
                title: t('error.change-subscription-end-date'),
                variant: 'destructive',
            }),
    });

    const [setCampwireEndDate] = useMutation(
        CHANGE_CAMPWIRE_SUBSCRIPTION_END_DATE,
        {
            onError: () =>
                toast({
                    title: t('error.change-subscription-end-date'),
                    variant: 'destructive',
                }),
        },
    );

    const changeUserState = async (isActive: boolean, userId: number) => {
        const state = isActive ? 1 : 0;
        await setUserStateMutationVariables({ state, userId });
        await setUserState();
        setAllUserSalesPagesState();
    };

    const [row, setRow] = useState<Subscription>(subscription);
    const [editMode, setEditMode] = useState(false);
    const [newEndDate, setNewEndDate] = useState(subscription.endDate);
    const [editCampwireDateMode, setEditCampwireDateMode] = useState(false);
    const [newCampwireEndDate, setNewCampwireEndDate] = useState(
        subscription.campwireEndDate,
    );

    useEffect(() => {
        if (subscription) {
            setRow(subscription);
        }
    }, [subscription]);

    useEffect(() => {
        if (campwireUserFlagMutationVariables?.userId) {
            setCampwireUserFlag();
        }
    }, [campwireUserFlagMutationVariables, setCampwireUserFlag]);

    const setSubscriptionEndDate = (id: number, endDate: string) => {
        setEndDate({
            variables: {
                subscriptionId: id,
                endDate,
            },
        });
    };

    const setCampwireSubscriptionEndDate = (
        id: number,
        endDate: string,
        campwireEndDate: string,
        campwireStartDate: string,
    ) => {
        setCampwireEndDate({
            variables: {
                subscriptionId: id,
                endDate,
                campwireEndDate,
                campwireStartDate,
            },
        });
    };

    const handleChange = (value: Date | undefined) => {
        setNewEndDate(moment(value).endOf('day').toISOString(true));
    };

    const handleCampwireDateChange = (value: Date | undefined) => {
        setNewCampwireEndDate(moment(value).endOf('day').toISOString(true));
    };

    const handleEditSave = () => {
        if (newEndDate) {
            setRow({
                ...row,
                endDate: newEndDate,
            });
            setSubscriptionEndDate(row.id, newEndDate);
        }
        setEditMode(false);
    };

    useEffect(() => {
        if (newCampwireEndDate && row.campwireEndDate === newCampwireEndDate) {
            setCampwireSubscriptionEndDate(
                row.id,
                row.endDate,
                row.campwireEndDate,
                row.campwireStartDate,
            );
            if (!row.subscriptionsUsers.campwireUser) {
                setCampwireUserFlagMutationVariables({
                    campwireUser: true,
                    userId: row?.subscriptionsUsers?.id,
                });
            }
        }
    }, [row, newCampwireEndDate]);

    const handleEditCampwireDateSave = async () => {
        if (newCampwireEndDate) {
            if (
                newCampwireEndDate > newEndDate ||
                newCampwireEndDate > row.campwireEndDate ||
                !row.campwireEndDate
            ) {
                const cwStartDate = row.campwireStartDate
                    ? row.campwireStartDate
                    : moment().startOf('day').toISOString(true);
                const eezyPayEndDate =
                    row.endDate && row.endDate > newCampwireEndDate
                        ? row.endDate
                        : newCampwireEndDate;
                await setRow({
                    ...row,
                    campwireStartDate: cwStartDate,
                    endDate: eezyPayEndDate,
                    campwireEndDate: newCampwireEndDate,
                });
            } else {
                await setRow({
                    ...row,
                    campwireEndDate: newCampwireEndDate,
                });
            }
        }
        setEditCampwireDateMode(false);
    };

    return (
        <Dialog
            title={t('subscriptions.edit-subscription')}
            open={open}
            setOpen={setOpen}
        >
            <div className="grid sm:grid-cols-2 gap-4">
                <div className="sm:col-span-2 mb-4">
                    <div className="font-bold text-lg">{t('user.user')}:</div>
                    <div>
                        <b>{t('user.full-name')}: </b>
                        {row?.subscriptionsUsers?.firstName}{' '}
                        {row?.subscriptionsUsers?.lastName}
                    </div>
                    <div>
                        <b>{t('user.email')}:</b>{' '}
                        {row?.subscriptionsUsers?.email}
                    </div>
                    <div>
                        <b>{t('user.phone')}:</b>{' '}
                        {row?.subscriptionsUsers?.phone}
                    </div>
                    <div>
                        <b>{t('user.language')}: </b>
                        {t(`language.${row?.subscriptionsUsers?.language}`)}
                    </div>
                    <div className="flex gap-2">
                        <div>
                            <b>{t('sales-page.active')}</b>
                            <Switch
                                checked={!!row?.subscriptionsUsers?.state}
                                onChange={(val) => {
                                    setRow({
                                        ...row,
                                        subscriptionsUsers: {
                                            ...row?.subscriptionsUsers,
                                            ...{
                                                state: val.target.checked
                                                    ? 1
                                                    : 0,
                                            },
                                        },
                                    });
                                    changeUserState(
                                        val.target.checked,
                                        row?.subscriptionsUsers?.id,
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <b>{t('user.campwire-user')}:</b>{' '}
                            <Switch
                                checked={
                                    !!row?.subscriptionsUsers?.campwireUser
                                }
                                onChange={(val) => {
                                    setRow({
                                        ...row,
                                        subscriptionsUsers: {
                                            ...row?.subscriptionsUsers,
                                            ...{
                                                campwireUser:
                                                    val.target.checked,
                                            },
                                        },
                                    });
                                    setCampwireUserFlagMutationVariables({
                                        campwireUser: val.target.checked,
                                        userId: row?.subscriptionsUsers?.id,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="font-bold text-lg">
                        {t('subscriptions.subscription')}:
                    </div>
                    <div>
                        <b>{t('subscriptions.start-date')}: </b>
                        {formatDate(row?.startDate)}
                    </div>
                    {!editMode && (
                        <div>
                            <b>{t('subscriptions.end-date')}:</b>{' '}
                            {formatDate(row?.endDate)}
                        </div>
                    )}
                    {editMode && (
                        <>
                            <div>
                                <b>{t('subscriptions.end-date')}:</b>{' '}
                            </div>
                            <DatePicker
                                value={newEndDate}
                                onChange={handleChange}
                                minDate={moment(row?.startDate).toDate()}
                            />
                            <div className="my-4 flex gap-2 flex-wrap">
                                {monthIntervals.map((months) => (
                                    <Button
                                        key={`${months}-end-date`}
                                        variant="outline"
                                        className="p-2"
                                        onClick={() => {
                                            const startDate = moment().isAfter(
                                                moment(newEndDate),
                                            )
                                                ? moment()
                                                : moment(newEndDate);
                                            const newDate = startDate
                                                .add(months, 'months')
                                                .toDate();
                                            handleChange(newDate);
                                        }}
                                    >
                                        +{months}{' '}
                                        {t('subscriptions.month-shorthand')}
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                    <div>
                        <Button
                            color="info"
                            onClick={() => {
                                if (editMode) {
                                    setEditMode(false);
                                    setNewEndDate(row?.endDate);
                                } else {
                                    setEditMode(true);
                                }
                            }}
                            className="mt-2 mr-5"
                        >
                            {editMode
                                ? t('button.cancel')
                                : t('subscriptions.edit-date')}
                        </Button>
                        {editMode && (
                            <Button
                                color="info"
                                onClick={handleEditSave}
                                className="mt-2 mr-5"
                            >
                                {t('button.save-changes')}
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    <div className="font-bold text-lg">
                        {t('subscriptions.campwire-subscription')}:
                    </div>
                    <div>
                        <b>{t('subscriptions.start-date')}: </b>
                        {row?.campwireStartDate
                            ? formatDate(row?.campwireStartDate)
                            : '-'}
                    </div>
                    {!editCampwireDateMode && (
                        <div>
                            <b>{t('subscriptions.end-date')}:</b>{' '}
                            {row?.campwireEndDate
                                ? formatDate(row?.campwireEndDate)
                                : '-'}
                        </div>
                    )}
                    {editCampwireDateMode && (
                        <>
                            <div>
                                <b>{t('subscriptions.end-date')}:</b>{' '}
                            </div>
                            <DatePicker
                                value={
                                    newCampwireEndDate ||
                                    row.campwireEndDate ||
                                    ''
                                }
                                onChange={handleCampwireDateChange}
                                minDate={moment(
                                    row?.campwireStartDate,
                                ).toDate()}
                            />
                            <div className="my-4 flex gap-2 flex-wrap">
                                {monthIntervals.map((months) => (
                                    <Button
                                        key={`${months}-campwire-end-date`}
                                        variant="outline"
                                        className="p-2"
                                        onClick={() => {
                                            const startDate = moment().isAfter(
                                                moment(newCampwireEndDate),
                                            )
                                                ? moment()
                                                : moment(newCampwireEndDate);
                                            const newDate = startDate
                                                .add(months, 'months')
                                                .toDate();
                                            handleCampwireDateChange(newDate);
                                        }}
                                    >
                                        +{months}{' '}
                                        {t('subscriptions.month-shorthand')}
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                    <div>
                        <Button
                            color="info"
                            onClick={() => {
                                if (editCampwireDateMode) {
                                    setEditCampwireDateMode(false);
                                    setNewCampwireEndDate(row?.campwireEndDate);
                                } else {
                                    setEditCampwireDateMode(true);
                                }
                            }}
                            className="mt-2 mr-5"
                        >
                            {editCampwireDateMode
                                ? t('button.cancel')
                                : t('subscriptions.edit-date')}
                        </Button>
                        {editCampwireDateMode && (
                            <Button
                                color="info"
                                onClick={handleEditCampwireDateSave}
                                className="mt-2 mr-5"
                            >
                                {t('button.save-changes')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default SubscriptionsModal;
