import { gql } from '@apollo/client';

const CHANGE_CAMPWIRE_SUBSCRIPTION_END_DATE = gql`
    mutation ChangeCampwireSubscriptionEndDate(
        $subscriptionId: Int!
        $endDate: timestamptz!
        $campwireEndDate: timestamptz!
        $campwireStartDate: timestamptz!
    ) {
        update_subscriptions_by_pk(
            pk_columns: { id: $subscriptionId }
            _set: {
                endDate: $endDate
                campwireStartDate: $campwireStartDate
                campwireEndDate: $campwireEndDate
                reminderSent168: null
                reminderSent72: null
                reminderSent6: null
                campwireReminderSent168: null
                campwireReminderSent72: null
                campwireReminderSent6: null
            }
        ) {
            id
            endDate
            campwireStartDate
            campwireEndDate
        }
    }
`;

export default CHANGE_CAMPWIRE_SUBSCRIPTION_END_DATE;
